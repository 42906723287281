export const qcBotItems = [
  {
    id: 1,
    parentText: "QC Bot",
    text: "Pallet Review",
    name: "Pallet Review",
    path: "/qcbot",
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 5,
    parentText: "QC Bot",
    text: "Pallet Archive",
    name: "Pallet Archive",
    path: "/palletarchive",
    onClick: () => {
      //navigate("/");
    },
  },
];

export const sideBotItems = [
  {
    id: 1,
    parentText: "QC Bot",
    text: "Side 1",
    name: "Pallet Review",
    path: "/qcbot",
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 2,
    parentText: "QC Bot",
    text: "Side 2",
    name: "Pallet Archive",
    path: "/palletarchive",
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 3,
    parentText: "QC Bot",
    text: "Side 3",
    name: "Pallet Archive",
    path: "/palletarchive",
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 4,
    parentText: "QC Bot",
    text: "Side 4",
    name: "Pallet Archive",
    path: "/palletarchive",
    onClick: () => {
      //navigate("/");
    },
  },
];

export const loadValidationItems = [
  {
    id: 3,
    parentText: "Load Validation",
    text: "Dock Door Controls",
    name: "Dock Door Controls",
    path: "/dockdoorcontrol",
    onClick: () => {
      //navigate("/");
    },
  },
  // {
  //   id: 3,
  //   parentText: "Load Validation",
  //   text: "Dock Door Control",
  //   name: "Dock Door Control",
  //   path: "/dockdoorcontrol",
  //   onClick: () => {
  //     //navigate("/");
  //   },
  // },
];
export const warehouseInsightItems = [
  {
    id: 4,
    parentText: "Warehouse Insights",
    text: "Operational Performance",
    name: "Operational Performance",
    path: "/operational-performance",
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 6,
    parentText: "Warehouse Insights",
    text: "Selector Details",
    name: "Picker Detail",
    path: "/picker-detail",
    onClick: () => {
      //navigate("/");
    },
  },
];

export const deliveryInsights = [
  {
    id: 7,
    parentText: "Delivery Insights",
    text: "Route Status",
    name: "Route Status",
    path: "/routestatus",
    visible:false,
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 8,
    parentText: "Delivery Insights",
    text: "Assets",
    name: "Assets",
    path: "/assets",
    visible:false,
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 9,
    parentText: "Delivery Insights",
    text: "Lifts",
    name: "Lifts",
    path: "/lifts",
    visible:false,
    onClick: () => {
      //navigate("/");
    },
  },
  {
    id: 10,
    parentText: "Delivery Insights",
    text: "Delivery Performance",
    name: "Delivery Performance",
    path: "/casesperhour",
    visible:false,
    onClick: () => {
      //navigate("/");
    },
  },

];
