import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid, FormControl, InputLabel, Select, Button, MenuItem, TextField, CircularProgress,IconButton } from "@mui/material"
import dayjs from 'dayjs';
import { Constants } from "../components/utils/Constants";
import { getRoutesList, getRoutesSummary, getAssetsSummary, getLiftsSummary, getCustomerAndLocations, customerUpdate, locationUpdate, getLiftsList,getLiftQData } from "../redux/Thunk/RoutesThunk";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocation } from "react-router-dom";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {GlobalConstants} from "../../utils/GlobalConstants";

function TopHeader({ selectedCustomerListData,
    selectedLocationListData,
    updateSelectedLocation,
    getRouteList,
    getRouteSummary,
    getAssetSummary,
    getLiftSummary,
    updateSelectedCustomer,
    customerAndLocationsData,
    getLiftList,
    customerAndLocationsDataLoad,
    userRoleName,
    setSelectedCheckboxes,
                       operationsPerformanceQURLFromPros,
                       loadingOperationURLFromProps,
                       getAmiQ,
    setRouteData }) {
    let location = useLocation();
    const [selectedRoutesDate, setSelectedRoutesDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
    const [selectDate, setSelectDate] = useState(localStorage.getItem("routeDate") ? dayjs(localStorage.getItem("routeDate")) : dayjs(new Date()));//.format("YYYY-MM-DD"));

    const [embedQUrl, setEmbedQUrl] = useState(null)
    const [indicatorOn, setIndicatorOn] = useState(false);
    // const [selectedLSCustomerListData, setSelectedLSCustomerListData] = useState(null);


    useEffect(() => {
        if (loadingOperationURLFromProps) {
            setIndicatorOn(true);
        } else if (indicatorOn) {
            setIndicatorOn(false);
        }
    }, [loadingOperationURLFromProps])


    // useEffect(() => {
    //     const storedCustomer = localStorage.getItem("selectedCustomer");
    //     if (storedCustomer) {
    //         setSelectedLSCustomerListData(JSON.parse(storedCustomer));
    //     }
    // }, []);

    useEffect(() => {
        if (operationsPerformanceQURLFromPros) {
            setEmbedQUrl(operationsPerformanceQURLFromPros);
        } else {
            setEmbedQUrl(null);
            setIndicatorOn(false);
        }
    }, [operationsPerformanceQURLFromPros])

    useEffect(() => {
        setEmbedQUrl(null);
        if (customerAndLocationsData.length > 0) {
            const storedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
            const storedLocation = JSON.parse(localStorage.getItem("selectedLocation"));
            let localstorageDate = localStorage.getItem("routeDate");
            if (!localstorageDate){
                localStorage.setItem("routeDate", selectedRoutesDate);
            }
            if (storedCustomer) {
                updateSelectedCustomer(storedCustomer);
            }
            if (storedLocation) {
                updateSelectedLocation(storedLocation);
            }

            let sDate = null;
            sDate = localStorage.getItem("routeDate");

            console.log("sDate......",sDate)
            // if (Constants.SELECT_DATE) {
            //     sDate = Constants.SELECT_DATE.format("YYYY-MM-DD");
            //     localStorage.setItem("routeDate", sDate)
            // } else {
            //     sDate = selectedRoutesDate;
            //     localStorage.setItem("routeDate", sDate)
            // }
            const customerLocationAndDate = {
                date: localStorage.getItem("routeDate") ? localStorage.getItem("routeDate") : selectedRoutesDate,
                locationId: storedLocation ? storedLocation.locationId : selectedLocationListData.locationId
            }
            const customerLocationdata = {
                customerId: storedCustomer ? storedCustomer.customerId : selectedCustomerListData.customerId,
                locationId: storedLocation ? storedLocation.locationId : selectedLocationListData.locationId
            }
            if (location.pathname === "/routestatus") {
                getRouteList(customerLocationAndDate);
                getRouteSummary(customerLocationAndDate);
                getAssetSummary(customerLocationdata);
                getLiftSummary(customerLocationdata);
            }
            if (location.pathname === "/lifts") {
                getLiftSummary(customerLocationdata);
                getLiftList(customerLocationdata);
            }
            if (location.pathname === "/assets" || location.pathname === "/assets/") {
                console.log(customerLocationdata, customerLocationAndDate);
                Constants.customerId = storedCustomer ? storedCustomer.customerId : selectedCustomerListData.customerId;
                Constants.locationId = storedLocation ? storedLocation.locationId : selectedLocationListData.locationId;
                dispatchHandleEvent(customerLocationdata, customerLocationAndDate);
            }
        }
    }, [customerAndLocationsData]);

    let dispatchHandleEvent = (locData, locDate) => {
        let obj = { locData: locData, locDate: locDate };
        const event = new CustomEvent(Constants.ASSET_HANDLE_CLICK_EVENT, { detail: obj });
        document.dispatchEvent(event);
    }

    //dayjs(new Date());
    const handleApply = () => {
        setSelectedCheckboxes([]);
        setRouteData([]);
        localStorage.setItem("routeDate", selectedRoutesDate);
        let sDate = null;
        sDate = localStorage.getItem('routeDate') ? localStorage.getItem('routeDate') : selectedRoutesDate;
        // if (Constants.SELECT_DATE) {
        //     sDate = Constants.SELECT_DATE.format("YYYY-MM-DD");
        // } else {
        //     sDate = selectedRoutesDate;
        // }
        const customerLocationAndDate = {
            date: sDate,
            locationId: selectedLocationListData.locationId
        }
        const customerLocationdata = {
            customerId: selectedCustomerListData.customerId,
            locationId: selectedLocationListData.locationId
        }

        // const selectedCustomer = event.target.value;
        // setSelectedLSCustomerListData(selectedCustomer);
        localStorage.setItem("selectedCustomer", JSON.stringify(selectedCustomerListData));
        localStorage.setItem("selectedLocation", JSON.stringify(selectedLocationListData));

        if (location.pathname === "/routestatus") {
            getRouteList(customerLocationAndDate);
            getRouteSummary(customerLocationAndDate);
            getAssetSummary(customerLocationdata);
            getLiftSummary(customerLocationdata);
        }
        if (location.pathname === "/lifts") {
            getLiftSummary(customerLocationdata);
            getLiftList(customerLocationdata);
        }
        if (location.pathname === "/assets" || location.pathname === "/assets/") {
            console.log(customerLocationdata, customerLocationAndDate);
            dispatchHandleEvent(customerLocationdata, customerLocationAndDate);
        }
    }

    const handleChangeDeliveryDate = (newValue) => {
        //selectDate = newValue;
        setSelectDate(newValue);
        Constants.SELECT_DATE = newValue;
        setSelectedRoutesDate(newValue?.format("YYYY-MM-DD"));
    };

    const handleCustomerChange = (event) => {
        updateSelectedCustomer(event.target.value)
        // const selectedCustomer = event.target.value;
        // setSelectedLSCustomerListData(selectedCustomer);
        // localStorage.setItem("selectedCustomer", JSON.stringify(selectedCustomer));
    }

    const handleLocationChange = (event) => {
        updateSelectedLocation(event.target.value)
    }

    let handleAMIAI = ()=>{
        getAmiQ();
    }
    const closeQ = () => {
        setEmbedQUrl(null);
    }

    return (
        <>
            {customerAndLocationsDataLoad ?
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "40px",
                        marginBottom: "10px",
                    }}
                >
                    <CircularProgress />
                </Box> :

                <Grid container spacing={2}>
                    {customerAndLocationsData && customerAndLocationsData?.length > 1 ?
                        <Grid item xs={3} lg={3} xl={3}>
                            <FormControl
                                size="medium"
                                variant="standard"
                                fullWidth
                            // disabled={distributionCentersData?.length === 0}
                            >
                                <InputLabel id="select-label">Customer</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="custDropdown"
                                    value={selectedCustomerListData || ""} // Ensure default value
                                    onChange={handleCustomerChange}
                                    renderValue={(selected) => selected?.customerName || "Select Customer"} // Properly display the name
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                "& .MuiMenuItem-root": {
                                                    transition: "background-color 0.2s",
                                                    "&:hover": {
                                                        backgroundColor: "#f5f5f5 !important", // Light gray hover effect
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {customerAndLocationsData.map((item) => (
                                       <MenuItem
                                            key={item.customerId}
                                            value={item}
                                            style={{
                                                backgroundColor: selectedCustomerListData?.customerId === item.customerId ? "#eeeef8" : "transparent", 
                                                "&:hover": {
                                                    backgroundColor: "#e0e0e0", // Light gray hover effect
                                                },
                                            }}
                                        >
                                            {item.customerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* <Select
                                    labelId="select-label"
                                    id="select-demo"
                                    value={selectedCustomerListData && selectedCustomerListData || ''}
                                    label="Customer"
                                    onChange={handleCustomerChange}
                                >
                                    {customerAndLocationsData && customerAndLocationsData.map((item) => (
                                        <MenuItem key={item.customerId} value={item}>
                                            {item.customerName}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                            </FormControl>
                        </Grid> :
                        userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly" ?
                            <Grid item xs={3} lg={3} xl={3}>
                                <TextField
                                    id={"txtCustomer"}
                                    label="Customer"
                                    value={selectedCustomerListData && selectedCustomerListData?.customerName}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid> :
                            <Grid item xs={3} lg={3} xl={3}>
                            </Grid>
                    }
                    {selectedCustomerListData && selectedCustomerListData?.locations?.length > 1 ?
                        <Grid item xs={3} lg={3} xl={3}>
                            <FormControl
                                size="medium"
                                variant="standard"
                                fullWidth
                            // disabled={distributionCentersData?.length === 0}
                            >
                                <InputLabel id="select-label">Location</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="locDropdown"
                                    value={selectedLocationListData || ""}
                                    onChange={handleLocationChange}
                                    renderValue={(selected) => selected?.locationName || "Select Location"}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                "& .MuiMenuItem-root": {
                                                    transition: "background-color 0.2s",
                                                    "&:hover": {
                                                        backgroundColor: "#f5f5f5 !important", // Light gray hover effect
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                  {/* selectedCustomerListData  {JSON.stringify(selectedCustomerListData?.locations)} */}
                                    {selectedCustomerListData?.locations?.map((item) => (
                                        <MenuItem 
                                            key={item.locationId} 
                                            value={item}
                                            style={{
                                                backgroundColor: selectedLocationListData.locationId === item.locationId ? "#eeeef8" : "transparent", 
                                                "&:hover": {
                                                    backgroundColor: "#e0e0e0", // Light gray hover effect
                                                },
                                            }}
                                        >
                                            {item.locationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* <Select
                                    labelId="select-label"
                                    id="select-demo"
                                    value={selectedLocationListData || ''}
                                    label="Customer"
                                    onChange={handleLocationChange}
                                >
                                    {selectedCustomerListData && selectedCustomerListData.locations.map((item) => (
                                        <MenuItem key={item.locationId} value={item}>
                                            {item.locationName}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                            </FormControl>
                        </Grid>
                        :
                        userRoleName === "RpcAdmin" || userRoleName === "RpcReadOnly" ?
                            <Grid item xs={3} lg={3} xl={3}>
                                <TextField
                                    id="txtLocation"
                                    label="Location"
                                    value={selectedCustomerListData && selectedCustomerListData?.locations[0]?.locationName}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid> :
                            <Grid item xs={3} lg={3} xl={3}></Grid>
                    }
                    {location.pathname === "/lifts" || location.pathname === "/assets"  || location.pathname === "/assets/"  ? "" :
                        <Grid item xs={3} lg={3} xl={3}>
                            <FormControl size="medium" variant="standard" fullWidth>
                                <DatePicker
                                    id={"datePicker"}
                                    defaultValue={selectDate}
                                    views={['year', 'month', 'day']}
                                    label="Delivery Date"
                                    format="MMMM D, YYYY"
                                    onChange={handleChangeDeliveryDate}
                                    slotProps={{ textField: { size: "small" } }}
                                    maxDate={dayjs(new Date())}
                                    sx={{
                                        marginTop: "9px",
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                                borderBottom: "1px solid",
                                                borderRadius: "0px",
                                            },
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={3} lg={3} xl={3} sx={{ marginTop: "10px", display: "flex", justifyContent: 'flex-end' }}>
                        <Button fullWidth variant="outlined" sx={{ width: "180px" }} id={"btnApply"}
                            onClick={handleApply}
                            disabled={!selectedRoutesDate}>
                            Apply
                        </Button>
                        &nbsp;&nbsp;
                        {(location.pathname === "/routestatus" && GlobalConstants.IS_IRIS_UI_VISIBLE)  ?
                            <Button fullWidth variant="outlined" sx={{ width: "180px" }}
                                    id={"btnIrisAI"} onClick={handleAMIAI}>
                                IRIS AI &nbsp;

                            </Button>:""}
                    </Grid>
                    <Grid item xs={3} lg={3} xl={3} sx={{ marginTop: "10px", display: "flex", justifyContent: 'flex-end' }}>
                        {(location.pathname === "/lifts" && GlobalConstants.IS_IRIS_UI_VISIBLE)  ?
                            <Button fullWidth variant="outlined" sx={{ width: "180px" }}
                                    onClick={handleAMIAI}>
                                IRIS AI &nbsp;

                            </Button>:""}
                    </Grid>
                    {indicatorOn ?
                        <div style={{
                            position: "absolute", width: '60%', height: '63vh', right: "2%", bottom: "10%", top: "16%", zIndex: 99
                        }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "60px",
                                    paddingBottom: "40px",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </div> : ''
                    }
                    {(location.pathname === "/lifts" || location.pathname === "/routestatus") && embedQUrl &&
                        <div style={{
                            position: "absolute", backgroundColor: "white", width: '80%', height: '63vh', right: "2%", bottom: "10%", top: "10%", zIndex: 999999
                        }}>
                            <IconButton aria-label="cancel"
                                        sx={{ position: 'absolute', margin: '-30px -20px 0px 0px',right:"1%",color:"#ff0000" }}
                                        onClick={closeQ}
                            >
                                <CancelPresentationIcon />
                            </IconButton>
                            <iframe
                                style={{ border: "2px solid black", borderRadius: "5px" }}
                                width="100%"
                                height="100%"
                                src={embedQUrl}
                            ></iframe>
                        </div>
                    }
                </Grid>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedCustomerId: state?.Routes?.selectedCustomer,
        selectedLocationId: state?.Routes?.selectedLocation,
        customerAndLocationsDataLoad: state?.Routes?.customerAndLocationsDataLoad,
        customerAndLocationsData: state?.Routes?.customerAndLocationsData,
        loadingCustomersDataFromProps: state?.Routes?.listofRoutesDataLoad,
        customersDataFromProps: state?.Routes?.listofRoutesData,
        loadingRoutesSummary: state?.Routes?.routesSummaryDataLoad,
        routesSummaryData: state?.Routes?.routesSummaryData,
        loadingAssetSummary: state?.Routes?.assetsSummaryDataLoad,
        assetsSummaryData: state?.Routes?.assetsSummaryData,
        loadingLiftSummary: state?.Routes?.liftSummaryDataLoad,
        liftsSummaryData: state?.Routes?.liftSummaryData,
        selectedLocationsList: state?.Routes?.selectedLocationsList,
        selectedCustomerListData: state?.Routes?.selectedCustomerListData,
        selectedLocationListData: state?.Routes?.selectedLocationListData,
        applicationIds: state?.Routes?.applicationIds,
        applicationIdsLoad: state?.Routes?.applicationIdsLoad,
        userRoleName: state?.Routes?.userRoleName,
        loadingOperationURLFromProps: state?.Routes?.loadingOperationURL,
        operationsPerformanceQURLFromPros: state?.Routes?.operationsPerformanceQURL
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAmiQ : () => dispatch(getLiftQData()),
        getCustomerAndLocations: () => dispatch(getCustomerAndLocations()),
        getRouteList: (customerLocationAndDate) => dispatch(getRoutesList(customerLocationAndDate)),
        getRouteSummary: (customerLocationAndDate) => dispatch(getRoutesSummary(customerLocationAndDate)),
        getAssetSummary: (customerLocationdata) => dispatch(getAssetsSummary(customerLocationdata)),
        getLiftSummary: (customerLocationdata) => dispatch(getLiftsSummary(customerLocationdata)),
        updateSelectedCustomer: (data) => dispatch(customerUpdate(data)),
        updateSelectedLocation: (data) => dispatch(locationUpdate(data)),
        getLiftList: (customerLocationdata) => dispatch(getLiftsList(customerLocationdata)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);